import { createApp, VueElement } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';


import '@/assets/styles/border.css'
import '@/assets/styles/reset.css'
import '@/router/permission.js'
// 引入自定义指令
//import '@/util/directives.js';

//import directive from './components/drag/directive';

// 引入自定义指令
//import directive from '@/util/directives';

/* 注册全局指令 --拖拽 */
//import directive from "@/util/drag/directive"
//import directive from '@/util/directives.js'



import SvgIcon from '@/icons'

import zhCn from 'element-plus/es/locale/lang/zh-cn'

import horizontalScroll from 'el-table-horizontal-scroll'



// 拖拽
//import dialogDrag from '@/util/dialogDrag.js'

// import dialogdrag from "@/util/directive/el-drag-dialog";

const app = createApp(App);

// 注册自定义指令
SvgIcon(app);
app.use(ElementPlus, {
    locale:zhCn,
}) // // UI库语言
//.use(directive) // 对话框的拖拽
.use(store) // 简易状态
.use(router) // 路由
.use(ElementPlus) // UI库
.use(horizontalScroll)
.mount('#app')
