import { createStore } from 'vuex'
import router from '@/router';

export default createStore({
  state: {
    hasRoutes: false,
    token: "",
    menuList: null,
    userInfo: null,
    editableTabsValue: '/index',
    editableTabs: [
      {
        title: '首页',
        name: '/index'
      }
    ]
  },
  getters: {
    GET_TOKEN:state=>{
      return state.token||sessionStorage.getItem("token");
    },
    GET_MENULIST:state=>{
      return state.menuList||JSON.parse(sessionStorage.getItem("menuList"))
    },
    GET_USERINFO:state=>{
      return state.userInfo||JSON.parse(sessionStorage.getItem("userInfo"))
    },
  },
  mutations: {
    SET_TOKEN:(state, token)=>{
      state.token = token;
      sessionStorage.setItem("token",token);
    },
    SET_MENULIST:(state, menulist)=>{
      state.menuList = menulist;
      sessionStorage.setItem("menulist",JSON.stringify(menulist));
    },
    SET_USERINFO:(state, userInfo)=>{
      state.userInfo = userInfo;
      sessionStorage.setItem("userInfo",JSON.stringify(userInfo));
    },
    SET_ROUTES_STATE:(state, hasRoutes)=>{
      state.hasRoutes = hasRoutes;
    },
    ADD_TABS:(state, tab)=>{
      if ( state.editableTabs.findIndex(e=>e.name===tab.path)===-1) {
        state.editableTabs.push({
          title:tab.name,
          name:tab.path
        })
      }
      state.editableTabsValue=tab.path;
    },
    RESET_TABS:(state)=>{
      state.editableTabsValue='/index';
      state.editableTabs=[
      {
        title: '首页',
        name: '/index'
      }
    ]
    },
  },
  actions: {
    // 安全退出
    logout() {
      window.sessionStorage.clear();
      router.replace('/login')
    }
  },
  modules: {
  }
})
