import router from "@/router/index";
import store from "@/store";
// 路由守卫 
router.beforeEach((to,from,next)=>{
    const whiteList = ['/login','/downloads'] // 白名单
    let token = store.getters.GET_TOKEN;
    let hasRoutes = store.state.hasRoutes;
    let menuList=store.getters.GET_MENULIST;
    document.title = to.meta.title || '余音阅谱V1.0'; // 在路由元信息中设置标题
    if ( token ) {
        if ( !hasRoutes && menuList ) {
            bindRoute(menuList);
            store.commit('SET_ROUTES_STATE',true);
        }
        next();
    } else {
        if ( whiteList.includes(to.path)) {
            next();
        }
        else if ( to.path === '/drive/K24-02-USB-Win/K2402_Pedal.zip' ) {
            next('/downloads');
        }
        else if ( to.path === '/download' ) {
            next('/downloads');
        }
        else {
            next('/login');
        }
    }
})

// 动态绑定路由
const bindRoute=(menuList)=>{
    if ( menuList == null ) return;
    let newRoutes = router.options.routes;
    menuList.forEach(menu=>{
        if ( menu == null ) return;
        if ( menu.children) {
            menu.children.forEach(m=>{
                let route=menuToRoute(m,menu.name);
                if ( route) {
                    newRoutes[0].children.push(route);

                }
            })
        }
    })
    // 重新添加到路由
    newRoutes.forEach(route=>{
        router.addRoute(route)
    })
}

// 菜单对象转成路由对象
const menuToRoute=(menu,parentName)=>{
    if ( !menu.component ) {
        return null;
    } else {
        let route={
            name:menu.name,
            path:menu.path,
            meta:{
                parentName: parentName
            }
        }
        route.component=()=>import('@/views/'+menu.component+'.vue');
        return route;
    }
}