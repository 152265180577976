<template>
	<router-view></router-view>
</template>

<script>
</script>

<style>
html,
body,
#app {
	height: calc(100% - 4px);
}

.app-container {
	padding: 10px;
}
</style>
